import React from "react";
import "./style.css";

export const DescriptionField = ({
  transactionFee,
  amount,
  minTransaction,
}) => {
  const chargeAmount = parseFloat(transactionFee) + parseFloat(amount.value);
  return (
    <div className="description-field">
      <div className="description-field-text">Transaction Charges = ${transactionFee}
        {
          amount.value >= minTransaction ? 
          <span><br />You will be charged ${chargeAmount.toFixed(2)} and receive {amount.display}.</span>
          : null
        }
      </div>
    </div>
  );
};
